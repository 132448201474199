








import StaticPageDisplay from "../components/admin/static-pages/StaticPageDisplay.vue";
import Items from "@/components/landing/Items.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { StaticPageDisplay, Items },
})
export default class StaticPage extends Vue {
  isLoading = false;
  get page() {
    return this.$store.getters["Pages/current"];
  }
  get link() {
    return this.$route.params.link;
  }
  get pageContent() {
    return this.page?.content;
  }

  @Watch("link")
  onLinkChanged() {
    this.fetchPage();
  }

  async fetchPage() {
    await this.$store.dispatch("Pages/fetchByLink", {
      link: this.link,
      token: this.$route.query.token,
    });
    window.scrollTo(0, 0);
  }

  async created() {
    this.isLoading = true;
    await this.fetchPage();
    this.isLoading = false;
  }
}
